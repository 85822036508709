import '../Monitor.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BusJourneyItem from './BusJourneyItem.jsx';
import BusTableHeader from './BusTableHeader.jsx';
import JourneyListFullscreenMessage from './JourneyListFullscreenMessage.jsx';
import MiscTrafficJourneyItem from './MiscTrafficJourneyItem.jsx';
import SmallTrainJourneyItem from './SmallTrainJourneyItem.jsx';
import SmallTrainTableHeader from './SmallTrainTableHeader.jsx';
import TrainJourneyItem from './TrainJourneyItem.jsx';
import TrainTableHeader from './TrainTableHeader.jsx';

const smallTrainWidth = 850;

class JourneyList extends Component {
  
  renderJourneyItems() {

    const maxHeight = 146;
    const noInfoHeight = 92;

    const listPaddingHeight = 20;
    const tableHeaderHeight = 27;

    var remainingHeight = this.props.height - listPaddingHeight - tableHeaderHeight;
    var count = 0;


    var journeys = this.props.journeys || []
    if (this.props.type !== 'train'){
      journeys.sort((a,b)=>{return (a.updatedTime || a.time) - (b.updatedTime || b.time)});  
    }
    
    var journeyItems = journeys.map((journey, index)=>{
      const height = (journey.canceled || journey.message)? maxHeight : noInfoHeight;
      if (remainingHeight >= height) {
        remainingHeight = remainingHeight - height;
        count = index + 1;
      } else {
        remainingHeight = 0;
      }

      if (this.props.type === "train"){
        return this.props.width < smallTrainWidth ? (<SmallTrainJourneyItem journey={journey} key={index} index={index} width={this.props.width}/>) : (<TrainJourneyItem journey={journey} key={index} index={index} width={this.props.width}/>);  
      } else if (this.typeIsBus(this.props.type) || this.props.type === "tram"){
        return (<BusJourneyItem journey={journey} key={index} index={index} width={this.props.width}/>);  
      }
      else {
        return (<MiscTrafficJourneyItem journey={journey} key={index} index={index} width={this.props.width}/>);
      }
    });

    return journeyItems.slice(0, count);
  }

  typeIsBus(type) {
      return type === "localBus" || type === "regionBus" || type === "mixedBus" || type === "commercial"
  }

  renderTableHeader() {
    if (this.props.type !== "train"){
      return (<BusTableHeader isDepartures={this.props.isDepartures}/>);
    } else {
      return this.props.width < smallTrainWidth ? (<SmallTrainTableHeader isDepartures={this.props.isDepartures}/>) : (<TrainTableHeader isDepartures={this.props.isDepartures}/>);
    }
  }

  renderMessage(){
    return (
        <JourneyListFullscreenMessage height={this.props.height} header={"Oops! Inga avgångar hittades..."} body={"Det beror förmodligen på att valt trafikslag inte avgår från hållplatsen."}/>
      ); 
  }

  renderJourneyTable(){
    return (
      <table>
          <thead>
            {this.renderTableHeader()}
          </thead>
          {this.renderJourneyItems()}
      </table>
    );
  }

  render() {
    return (
      <div className="JourneyList" style={{minHeight: this.props.height}}>
        {(this.props.journeys || []).length > 0 ? this.renderJourneyTable() : this.renderMessage()}
      </div>
    );
  }
}

JourneyList.propTypes = {
  height: PropTypes.number,
  journeys: PropTypes.arrayOf(PropTypes.object) 
};

export default JourneyList;
