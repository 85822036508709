import './ConfigApp.css';
import './ContentSizing.css';
import 'bootstrap/dist/css/bootstrap.css';

import React, { Component } from 'react';

import { background, logo, regionSkaneLogo } from '../Images.js';
import DownloadBatPage from './DownloadBatPage';
import SearchBox from './SearchBox.jsx';
import { Red } from '../App/Colors';


class ConfigApp extends Component {

    constructor(props) {
        super(props);  
        this.state = { fromPoint: undefined, from: '', points: {}, departure: 0, trafficTypes: [], stopPoint: '', width: this.getWidth(), apiKey: '', invalidApiKey: false, createBtnText: 'Skapa', createBtnLoading: false }
        window.onresize = () => {
            this.setState({ width: this.getWidth()});
        };
    }

    fromChanged(item) {
        this.setState({ fromPoint: item });
    }

    getMatchingPoints(text, callback) {
        if (text.length < 3) {
            callback([]);
            return;
        }

        if (this.state.points[text] != null && this.state.points[text].length > 0) {
            callback(this.state.points[text]);
            return;
        }
        
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('get', "api/Config/searchstoppoints?text=" + encodeURIComponent(text) , true);
        xhr.onload = function () {
            if (xhr.status !== 200) {
                this.setState({ error: xhr.status === 401 ? 'Unauthorized use of page' : 'Unknown error', loading: false });
                return;
            }
            var resultObject = JSON.parse(xhr.response);
            var pointsDictionary = this.state.points;
            pointsDictionary[text] = resultObject.startPoints;
            this.setState({ points: pointsDictionary });
            callback(pointsDictionary[text]);
        }.bind(this);
        xhr.send();
    }

    onTrafficTypeChanged(value, event) {
        var trafficTypes = this.state.trafficTypes;
        if (event.target.checked) {
            trafficTypes.push(value);
        } else {
            var index = trafficTypes.indexOf(value);
            if (index > -1) {
                trafficTypes.splice(index, 1);
            }
        }
        this.setState({trafficTypes: trafficTypes})
    }

    validateApiKey(apiKey) {
        this.setState({createBtnText: "Skapar...", createBtnLoading: true});
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('get', "api/Config/validate-api-key", true);
        xhr.setRequestHeader('x-api-key', this.state.apiKey);
        xhr.onload = function () {
            if (xhr.status === 401) {
                this.setState({createBtnText: "Skapa", createBtnLoading: false, invalidApiKey: true});
                return;
            }
            this.setState({ showDownload: true });
        }.bind(this);
        xhr.send();
    }

    renderConfigurationForm(){
        
        return (
            <div className="configurator-rounded-plate mx-md-3" style={{ marginTop: 'auto', marginBottom: 'auto'}}>
                <div className="configurator-header-plate" >
                    <h5 className="configurator-header">Skapa avgångstavla</h5>
                </div>
                <div className="configurator-content">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div style={{ width: 30, backgroundColor: 'white' }} className="input-group-text bold">{this.state.departure ? 'Till' : 'Från' }</div>
                        </div>
                        <SearchBox
                            placeholder="Hållplats"
                            inputStyle={{ height: 50, borderRadius: '0px 4px 4px 0px', paddingLeft: 12}}
                            style={{ flex: '1 1 auto', borderRadius: '0px 4px 4px 0px'}}
                            onSearch={(text, callback) => {
                                this.setState({ fromPoint: undefined });
                                this.getMatchingPoints(text, callback);
                            }}
                            onSelected={(item) => {
                                this.fromChanged(item);
                            }}
                            resultsLimit={7} />
                    </div>
                    <br/>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text bold" style={{ width: 30, backgroundColor:'white'}}>Läge</div>
                        </div>
                        <input style={{ height: 50 }} type="text" className="form-control col-4 col-sm-2" value={this.state.stopPoint} onChange={(event) => this.setState({ stopPoint: event.target.value })} />
                    </div>
                    
                    <br />
                    {/* Arrivals is not currently supported 
                    <select className="form-control" style={{height: 50, backgroundColor: '#fff'}} value={this.state.departure} onChange={(event) => {
                        this.setState({ departure: event.target.value * 1 }
                        )}}>
                        <option value={0}>Avgångar</option>
                        <option value={1}>Ankomster</option>
                    </select>
                    <br /> */}
                    <label className="bold" style={{marginBottom: 15}}>Välj trafikslag</label>
                    
                    <div className="form-check">
                        <input className="configurator-checkbox form-check-input" type="checkbox" id="Localbus" checked={this.state.trafficTypes.indexOf(1) > -1} onChange={(event) => { this.onTrafficTypeChanged(1, event)}}/>
                        <label className="configurator-checkbox-label form-check-label" htmlFor="Localbus">Stadsbuss</label>
                    </div>
                    <div className="form-check">
                        <input className="configurator-checkbox form-check-input" type="checkbox" id="Regionbus" checked={this.state.trafficTypes.indexOf(2) > -1} onChange={(event) => { this.onTrafficTypeChanged(2, event) }}/>
                        <label className="configurator-checkbox-label form-check-label" htmlFor="Regionbus">Regionbuss</label>
                    </div>
                    <div className="form-check">
                        <input className="configurator-checkbox form-check-input" type="checkbox" id="Tram" checked={this.state.trafficTypes.indexOf(8) > -1} onChange={(event) => { this.onTrafficTypeChanged(8, event) }} />
                        <label className="configurator-checkbox-label form-check-label" htmlFor="Tram">Spårvagn</label>
                    </div>
                    <div className="form-check">
                        <input className="configurator-checkbox form-check-input" type="checkbox" id="Train" checked={this.state.trafficTypes.indexOf(4) > -1} onChange={(event) => { this.onTrafficTypeChanged(4, event) }}/>
                        <label className="configurator-checkbox-label form-check-label"  htmlFor="Train">Tåg</label>
                    </div>
                    {/* Commercial bus is currently not supported
                     <div className="form-check">
                        <input className="configurator-checkbox form-check-input" type="checkbox" id="Comercialbus" checked={this.state.trafficTypes.indexOf(16) > -1} onChange={(event) => { this.onTrafficTypeChanged(16, event) }}/>
                        <label className="configurator-checkbox-label form-check-label" htmlFor="Comercialbus">Kommersiell buss</label>
                    </div> */}
                    
                    <br />
                    <label className="bold">Säkerhetsnyckel</label>
                    <div className={`input-group ${this.state.invalidApiKey ? "invalid-input" : ""}`}>
                        <input style={{ height: 50 }} type="text" className="form-control" value={this.state.apiKey} onChange={(event) => this.setState({ apiKey: event.target.value })} />
                    </div>
                    <div className={`error-message ${this.state.invalidApiKey ? "d-block" : "d-none"}`}>
                        Ogiltig säkerhetsnyckel. Vänligen kontakta Skånetrafikens företagsservice på 0771-77 75 75
                    </div>

                    
                    
                    
                    <br />
                    <br />
                    <div style={{width: '100%', alignItems: 'center', textAlign: 'center', paddingBottom: 24}}>
                        <button disabled={!this.state.fromPoint || this.state.trafficTypes.length === 0 || this.state.apiKey === '' || this.state.createBtnLoading } className={"btn btn-warning bold"} onClick={() => {
                            if (this.state.fromPoint && this.state.apiKey) {
                                this.validateApiKey(this.state.apiKey);
                            }
                        }} style={{
                            padding:'.45rem 1.8rem'
                        }}>{this.state.createBtnText}</button>
                    </div>
                </div>
            </div>
        );
    }

    getQuery(){
        return "?stationId=" + this.state.fromPoint.id + "&stationName=" +encodeURIComponent(this.state.fromPoint.name)+ "&trafficTypes=["+this.state.trafficTypes.toString() + "]&departure=" + this.state.departure + "&stopPoint=" +this.state.stopPoint + "&apiKey=" + this.state.apiKey;
    }

    renderDownloadPage(){
        var query = this.getQuery();
        return (
            <DownloadBatPage query={query} name={this.state.fromPoint.name}/>
        );
    }

    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }
    
    render() {
        var configHeader = "'Bitter', serif"
        return (
            <div className="App" style={{ position: 'absolute', zIndex: 0, backgroundColor: 'rgb(240,240,232)',  height: '100%', display: 'table', width: '100%' }}>
                {this.state.width > 450 && <div style={{ position: 'absolute', top: 100, left: 0, right: 0, zIndex: 0 }}><img style={{ width: '100%' }} src={background} alt=""/></div>}
                <div className="skanetrafiken-logo">
                    <img style={{ height: 32, paddingRight: 12 }} src={logo} alt="skånetrafiken"/>
                    <span style={{ lineHeight: 1, fontWeight: 400, position: 'absolute', bottom: 0, paddingLeft: 15, color: '#807569', fontSize: 20, fontFamily: configHeader }}>Molnskylt</span>
                </div>
                <div style={{ position: 'absolute', bottom: 10, right: 10 }}><img style={{ height: 65, padding: 20 }} src={regionSkaneLogo} alt=""/></div>
                <div className="container px-0 px-md-3 configuration-container">
                    {!this.state.showDownload? this.renderConfigurationForm() : this.renderDownloadPage()}
                </div>
            </div>
        );
    }
}

export default ConfigApp;
