import React, {Component} from 'react'

class DownloadBatPage extends Component{
    render() {
        // Create Download Url
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        let port = '';
        if( window.location.port !== '80' && window.location.port !== '443' ) {
            port = ':' + window.location.port;
        }
        const queryParams = this.props.query;
        const downloadLink = protocol + '//' + hostname + port + '/api/Config/downloadmonitor' + queryParams;
        const molnskyltUrl = protocol + '//' + hostname + port +  queryParams;

        function copyToClipboard() {
            const urlValue = document.getElementById('copyUrl').value;
            navigator.clipboard.writeText(urlValue);
            document.getElementById('copiedIndicator').style.display = 'block';
        }

        return (
            <div className="configurator-rounded-plate">
                <div className="configurator-header-plate">
                    <h5 className="configurator-header">Konfigurera skylt</h5>
                </div>
                <div className="configurator-content">
                    <div className="bold" style={{ fontSize: 20, marginBottom: 10 }}>Skylt skapad. Du är snart klar...</div>
                    <p>Du har nu två alternativ. Alternativ 1 rekommenderas om du använder en modern webbläsare (ex. Chrome) eller om du har behov av att klistra in webbadressen i en separat applikation. Alternativ 2 är till för dig som använder dig av Windows och vill använda tjänsten på samma sätt som tidigare genom att vi skapar upp en fil som automatiskt öppnar upp en Molnskylt på din Windowsdator.</p>
                    <hr />
                    <div className="bold" style={{ fontSize: 18 }}>Alternativ 1: Använd befintlig webbläsare</div>
                    <ol style={{listStyleType: 'none', padding: 0, marginTop: 30}}>
                        <li style={{ marginBottom: '30px' }}>
                            <p className="bold" >1. Kopiera länken:</p>
                            <input type="text" defaultValue={molnskyltUrl} id="copyUrl" className="form-control col-9" style={{ display: 'inline-block', borderRadius: '0.25rem 0 0 0.25rem' }} />
                            <button onClick={copyToClipboard} className="btn btn-warning bold col-3" style={{ boxSizing: 'border-box', height: 'calc(1.5em + 0.75rem + 2px)', fontSize: '1rem', borderRadius: '0 0.25rem 0.25rem 0', verticalAlign: 'baseline' }}>Kopiera</button>
                            <p id="copiedIndicator" style={{ color: 'green', display: 'none' }}>Kopierad!</p>
                        </li>
                        <li>
                            <p className="bold" >2. Klista in länken i din applikation eller webbläsare:</p>
                            <p>Observera att du manuellt behöver dölja adressfältet i din webbläsare. Detta gör du genom att trycka <kbd className="bold" style={{ border: '1px solid grey', borderRadius: '.25rem', backgroundColor: '#e9ecef', padding: 1, fontSize: '.9rem', color: 'black', fontWeight: '600 !important' }}>F11</kbd> i Windows och Linux.</p>
                            <p className="bold" >Klart!</p>
                        </li>
                    </ol>
                    <hr />
                    <div className="bold" style={{ fontSize: 18 }}>Alternativ 2: Ladda ned BAT-fil</div>
                    <ol style={{listStyleType: 'none', padding: 0, marginTop: 30}}>
                        <li>
                            <p className="bold" >1. Ladda ner följande fil:</p>
                            <div style={{ marginBottom: 30 }}><a href={downloadLink}>Windows-kommandofil för {this.props.name}</a></div>

                            <p>Filen innehåller länk till molnskylt för den valda hållplatsen. Klicka på länken ovan och välj "Spara" för att ladda hem filen. Du kan också testa molnskylten genom att klicka på länken och välja "kör".</p>
                        </li>
                        <li>
                            <p className="bold" >2. Lägg till filen i Autostart:</p>

                            <p>Högerklicka på "Autostart" (under Start/Program) och välj öppna. Lägg filen i Autostartmappen.</p>

                            <p className="bold" >Klart!</p>

                            <p>Starta om Windows för att testa din konfiguration. Se över inställningar för autologon (automatisk nätverksinloggning)</p>
                        </li>
                        <br/>
                    </ol>
                </div>
            </div>
        );
    }
}

export default DownloadBatPage;
