import '../Monitor.css';

import React, { Component } from 'react';

import Colors from './Colors.js';
import { headerEdgeGray, headerEdgeGreen, headerEdgeYellow } from '../Images.js';

class Header extends Component {
  renderIcon(){    
    if(this.props.type === "train") {
      return (
        <svg className="svgConnTypeTitleIcon" version="1.1" x="0px" y="0px" width="78px" height="80px" viewBox="0 0 86 91">
          <path d="M73.3,22.9c-0.1-1.9-0.5-9.5-6.5-13.4c-3.1-2-6.1-2.1-12.2-2.3h-0.8C52.2,3,48.2,0,43.4,0s-8.8,3-10.3,7.2h-0.6 c-6.1,0.2-9.1,0.3-12.2,2.3c-6.1,3.9-6.4,11.5-6.5,13.4c-0.1,1.9-3.7,41.7-3.7,41.7c0.2,2.1,0.7,6.6,6.4,10.2 c3.2,2,9.4,3.1,15.5,3.3h23.2c6.1-0.2,12.3-1.4,15.5-3.3c5.6-3.5,6.1-8.1,6.4-10.2C77,64.6,73.4,24.8,73.3,22.9z M43.4,5.5 c3.1,0,5.6,2.5,5.6,5.6s-2.5,5.6-5.6,5.6s-5.6-2.5-5.6-5.6S40.3,5.5,43.4,5.5z M21,69.2c-3.1,0-5.6-2.5-5.6-5.6 c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6C26.5,66.7,24,69.2,21,69.2z M60.1,32.3c0,1.1-2,14.3-2,14.3c-0.1,1.2-0.4,3.5-3.6,5.5 c-1.8,1.1-5.2,1.6-8.7,1.7H41c-3.5-0.1-6.9-0.6-8.7-1.7c-3.2-2-3.5-4.3-3.6-5.5c0,0-1.9-13.2-2-14.3c0-1.1-1-5.2,2.9-7.7 c1.8-1.1,3.5-1.2,7.1-1.4h13.5c3.5,0.1,5.2,0.2,7.1,1.4C61.1,27.1,60.1,31.2,60.1,32.3z M65.7,69.2c-3.1,0-5.6-2.5-5.6-5.6 c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C71.3,66.7,68.8,69.2,65.7,69.2z"/>
          <path d="M62.6,80.9h-0.7c-1.7,0.2-3.3,0.3-5,0.4H30.3c-1.7-0.1-3.4-0.2-5-0.4h-0.8l0.1-0.1c-3.9-0.5-7.4-1.3-10-2.4 c-0.3,0.2-0.6,0.5-0.8,0.7l-6.2,7.7c-0.9,1.1-0.6,2,0.6,2l8.5,0c1.2,0,2.2-0.9,3.1-2l0.5-0.5H67l0.5,0.5c0.9,1.1,1.8,2,3.1,2l8.5,0 c1.2,0,1.5-0.9,0.6-2l-6.2-7.7c-0.2-0.3-0.5-0.5-0.7-0.7C69.9,79.5,66.4,80.3,62.6,80.9L62.6,80.9z"/>
        </svg>
      );
    }
    else {
      return (
        <svg className="svgConnTypeTitleIcon" version="1.1" x="0px" y="0px" width="78px" height="80px" viewBox="0 0 86 91">
          <path style={{fill: this.getColor()}} d="M76.5,25.4h-1.6c-0.2-3.4-0.5-7-0.8-11c-0.3-2.1-1.1-6.9-7-10.6c-3.3-2.1-11.4-3.2-17.8-3.4H35.4 C29,0.6,23.5,1.7,20.2,3.8c-5.9,3.7-7,8.5-7.2,10.6c0,0-0.3,4.6-0.8,11h-1.7c-2.1,0-3.9,1.7-3.9,3.9v11.1c0,1.8,2.2,5.1,4.1,6.1 C10,56.4,9.5,65,9.4,65.8c0,2-1.7,9.1,4.7,13.6v7.2c0,1.1,0.9,1.9,1.9,1.9h5.5c1.1,0,1.9-0.9,1.9-1.9v-4.6c1.2,0.1,2.4,0.1,3.9,0.2 h31.8c1.4-0.1,2.6-0.1,3.6-0.2v4.6c0,1.1,0.9,1.9,1.9,1.9h5.5c1.1,0,1.9-0.9,1.9-1.9v-7.3c6.3-4.5,5-11.6,5-13.5 c0-1.4,0-5.2-0.9-19.3c2-1.1,4.1-4.3,4.1-6.1V29.3C80.4,27.2,78.6,25.4,76.5,25.4z M30.5,9.5c0-1.1,0.9-1.9,1.9-1.9h21.7 c1.1,0,1.9,0.9,1.9,1.9v1.6c0,1.1-0.9,1.9-1.9,1.9H32.4c-1.1,0-1.9-0.9-1.9-1.9V9.5z M21.1,72.8c-3.1,0-5.7-2.5-5.7-5.7 c0-3.1,2.5-5.7,5.7-5.7s5.7,2.5,5.7,5.7C26.8,70.3,24.2,72.8,21.1,72.8z M55.6,54.5c-2,1.3-5.9,1.8-9.8,1.9h-5.4 c-4-0.2-7.8-0.7-9.8-1.9c-3.7-2.3-3.9-4.9-4.1-6.2c0,0-2.2-14.9-2.2-16.1c0-1.2-1.1-5.9,3.2-8.7c2.1-1.3,4-1.4,7.9-1.5h15.2 c4,0.2,5.9,0.3,7.9,1.5c4.4,2.8,3.3,7.4,3.2,8.7c0,1.2-2.2,16.1-2.2,16.1C59.5,49.6,59.3,52.2,55.6,54.5z M59.9,67.2 c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7s-2.5,5.7-5.7,5.7S59.9,70.3,59.9,67.2z"/>
        </svg>
      );
    }    
  }

  getColor(){
    var color;
    switch(this.props.type){
      case "regionBus":
        color = Colors.Black;
        break;
      default:
        color = Colors.White;
    }
    return color;
  }

  getBackground(){
    var backgroundImage;
    switch(this.props.type) {
      case "train":
        backgroundImage = headerEdgeGray;
        break;
      case "localBus":
        backgroundImage = headerEdgeGreen;
        break;
      case "regionBus":
        backgroundImage = headerEdgeYellow;
        break;
      case "mixedBus":
        backgroundImage = headerEdgeGray;
        break;
      case "tram":
        backgroundImage = headerEdgeGreen;
        break;

      default:
        backgroundImage = headerEdgeGray;
    }
    return backgroundImage;
  }

  getBackgroundColor(){
    var backgroundColor;
    switch(this.props.type) {
      case "train":
        backgroundColor = Colors.Train;
        break;
      case "localBus":
        backgroundColor = Colors.LocalBus;
        break;
      case "regionBus":
        backgroundColor = Colors.RegionBus;
        break;
      case "mixedBus":
        backgroundColor = Colors.MixedBus;
        break;
      case "tram":
        backgroundColor = Colors.LocalBus;
        break;
      default:
        backgroundColor = Colors.Gray;
    }
    return backgroundColor;
    
  }

  render() {
    var fontSize = 40;
    var lineHeight = 0.95;
    var maxTitleWidth = (this.props.width || 180) -180;
    
    return (
      <div className="Header" style={{background: this.getBackgroundColor()}}>
        {this.renderIcon()}
        <div style={{background: '#FFFFFF', position: 'absolute', top: 0, right: 0, height: 90, width: 90}}></div>
        <div style={{background: '#FFFFFF', backgroundImage: 'url(' + this.getBackground() + ')', backgroundRepeat: 'no-repeat', backgroundSize: "90px 90px", position: 'absolute', top: 0, right: 0, height: 90, width: 90}}></div>
        <div className="Title" style={{whiteSpace:'normal', maxWidth: maxTitleWidth, color: this.getColor(), lineHeight: lineHeight, fontSize: fontSize, position: 'relative', display: 'inline-block', zIndex: 99}}>{this.props.title}</div>
      </div>
    );
  }
}

export default Header;
